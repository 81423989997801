import classNames from "classnames";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Present.module.css";
import invite_gift from "../../../assets/invite_gift.png";
import kakao_icon from "../../../assets/kakao_icon.png";
import axios from "axios";
import Error from "../../../common/Error";
import SimpleDialog from "../../../common/SimpleDialog";
import styled from "@emotion/styled";
import { useApi } from "../../../hooks";
import BackHeader from "../../../common/BackHeader";

const DEFAULT_MESSAGE = "당신을 우리회사에 추천하고 싶어요~!";

const Present = () => {
  const { autho } = useApi();

  const navigate = useNavigate();
  const location = useLocation();
  const givenName = location.state?.name || "";
  const givenMessage = location.state?.message || "";
  const { Kakao } = window;
  const [apiError, setApiError] = useState(false);
  const [isKakaoChannel, setIsKakaoChannel] = useState(true);
  const [message, setMessage] = useState(givenMessage);
  const [receiver, setReceiver] = useState(givenName);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nameSubmitOpenModal, setNameSubmitOpenModal] = useState(false);
  const [phoneSubmitOpenModal, setPhoneSubmitOpenModal] = useState(false);
  const [inquiryOpenModal, setInquiryOpenModal] = useState(false);
  const [placeholderData, setPlaceholderData] = useState("010-0000-0000");
  const onChangeMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };
  const onChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pureNumber = event.target.value.replace(/[^0-9]/g, "");
    const changedNumber = pureNumber
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/(\-{1,2})$/g, "");
    let changedPlaceholder = "";
    const defaultPlaceholder = "010-0000-0000";
    for (let i = 0; i < defaultPlaceholder.length; i++) {
      if (i < changedNumber.length) changedPlaceholder += changedNumber[i];
      else changedPlaceholder += defaultPlaceholder[i];
    }
    setPlaceholderData(changedPlaceholder);
    setPhoneNumber(changedNumber);
  };

  const onClickSendInvitation = () => {
    if (!receiver) {
      setNameSubmitOpenModal(true);
      return;
    }

    if (!isKakaoChannel && phoneNumber.length < 13) {
      setPhoneSubmitOpenModal(true);
      return;
    }

    const config = {
      headers: { Authorization: `Bearer ${autho.token}` },
    };

    if (isKakaoChannel) {
      axios
        .get("/invitations/share", config)
        .then((res) => {
          const kakaoUrl = res.data.url;
          const kakaoId = res.data.id;
          const apiCompany = res.data.company;
          const shareTitle = res.data.title;
          axios
            .get("/inviters/me", config)
            .then((r) => {
              const sharedImage = r.data.company.shareImage;

              axios.post(
                "/invitations/kakaoshare",
                {
                  message: message === "" ? DEFAULT_MESSAGE : message,
                  name: receiver,
                  inviterId: autho.id,
                  invitationId: kakaoId,
                },
                config
              );

              Kakao.Share.sendDefault({
                objectType: "feed",
                content: {
                  title: shareTitle,
                  description: "나의 정보 등록하고 커피챗 제안을 받아보세요!",
                  imageUrl: sharedImage,
                  link: {
                    // [내 애플리케이션] > [플랫폼] 에서 등록한 사이트 도메인과 일치해야 함
                    mobileWebUrl: kakaoUrl,
                    webUrl: kakaoUrl,
                  },
                },
                serverCallbackArgs: {
                  inviterId: autho.id,
                  invitationId: kakaoId,
                  message: message === "" ? DEFAULT_MESSAGE : message,
                  name: receiver,
                },
                buttons: [
                  {
                    title: "정보 등록하고 커피챗 제안 받기",
                    link: {
                      mobileWebUrl: kakaoUrl,
                      webUrl: kakaoUrl,
                    },
                  },
                ],
              });

              navigate(`/worker/invite/present_confirm`, {
                state: {
                  name: receiver,
                  message: message === "" ? DEFAULT_MESSAGE : message,
                  company: apiCompany,
                },
              });
            })
            .catch((e) => {
              setApiError(true);
            });
        })
        .catch((e) => setApiError(true));
    } else {
      axios
        .post(
          "/invitations/lms",
          {
            message: message === "" ? DEFAULT_MESSAGE : message,
            name: receiver,
            phone: phoneNumber.replace(/[^0-9]/g, ""),
          },
          config
        )
        .then((res) => {
          navigate(`/worker/invite/complete`);
        })
        .catch((e) => setApiError(true));
    }
  };
  const onClickBack = () => {
    navigate(`/worker/invite/home`);
  };

  const onNameSubmit = () => {
    setNameSubmitOpenModal(false);
  };

  const onPhoneSubmit = () => {
    setPhoneSubmitOpenModal(false);
  };

  const onInquiry = () => {
    setInquiryOpenModal(false);
  };
  useEffect(() => {
    if (!Kakao.isInitialized()) Kakao.init(process.env.REACT_APP_KAKAO);
  }, []);

  if (autho.token) {
    return (
      <>
        {apiError && <Error />}
        {!apiError && (
          <div className={styles.background}>
            <div>
              <div className={styles.content}>
                <BackHeader onClickBack={onClickBack} />
                <div className={styles.message_wrap}>
                  <img
                    className={styles.gift_img}
                    alt="invite_gift"
                    src={invite_gift}
                  />
                  <div className={styles.message_area}>
                    <div className={styles.message_title}>
                      초대받는 분에게 메시지 작성하기
                    </div>
                    <div className={styles.message_content}>
                      <textarea
                        className={styles.message_input}
                        placeholder="당신을 우리회사에 추천하고 싶어요~!"
                        maxLength={100}
                        onChange={onChangeMessage}
                        value={message}
                      ></textarea>
                      <div className={styles.message_length}>
                        {message.length}/100
                      </div>
                    </div>
                  </div>
                </div>
                <InquiryText
                  onClick={() => {
                    setInquiryOpenModal(true);
                  }}
                >
                  초대 프로세스가 어떻게 되나요?
                </InquiryText>
                <div className={styles.recipient_info}>
                  <div className={styles.recipient_title}>초대받는 분 정보</div>
                  <div className={styles.channelSelection}>
                    <button
                      className={classNames(
                        styles.menu_btn,
                        isKakaoChannel ? styles.active_btn : styles.unactive_btn
                      )}
                      onClick={() => setIsKakaoChannel(true)}
                    >
                      카카오톡으로 발송
                    </button>
                    <button
                      className={classNames(
                        styles.menu_btn,
                        isKakaoChannel ? styles.unactive_btn : styles.active_btn
                      )}
                      onClick={() => setIsKakaoChannel(false)}
                    >
                      문자 LMS로 발송
                    </button>
                  </div>
                  <div className={styles.recipient_input}>
                    <div className={styles.info_title}>받는 분 성함</div>
                    <input
                      className={styles.info_input}
                      placeholder="실명을 입력해주세요"
                      onChange={(e) => setReceiver(e.target.value)}
                      value={receiver}
                    ></input>
                    {!isKakaoChannel && (
                      <div>
                        <div
                          className={classNames(
                            styles.info_title,
                            styles.phone_title
                          )}
                        >
                          휴대전화 번호
                        </div>
                        <div className={styles.placeholder}>
                          <input
                            placeholder={placeholderData}
                            className={styles.info_input}
                            onChange={onChangePhoneNumber}
                            value={phoneNumber}
                            type="tel"
                          ></input>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <button
                  className={classNames(
                    styles.send_invitation,
                    isKakaoChannel ? styles.send_kakao : styles.send_text
                  )}
                  onClick={onClickSendInvitation}
                >
                  <div className={styles.div_wrap}>
                    {isKakaoChannel && (
                      <img
                        className={styles.kakao_icon}
                        alt="kakao_icon"
                        src={kakao_icon}
                      />
                    )}
                    <div>
                      {isKakaoChannel ? "받는 분 선택하기" : "초대장 보내기"}
                    </div>
                  </div>
                </button>
                <SimpleDialog
                  onConfirm={onNameSubmit}
                  open={nameSubmitOpenModal}
                  title={"받는 분 성함을 입력해 주세요."}
                  desc={"받는 분의 실명 입력이 필요합니다."}
                  btnText={"확인"}
                />
                <SimpleDialog
                  onConfirm={onPhoneSubmit}
                  open={phoneSubmitOpenModal}
                  title={"휴대전화 번호를 입력해 주세요."}
                  desc={"받는 분의 휴대전화 번호로 초대장이 발송됩니다."}
                  btnText={"확인"}
                />
                <SimpleDialog
                  onConfirm={onInquiry}
                  open={inquiryOpenModal}
                  title={"초대장을 발송하면 친구가 정보를 등록할 수 있어요!"}
                  desc={
                    "받은 분이 정보등록을 완료하면, 내가 추천사유를 작성하고 인사팀이 확인 후 커피챗을 제안합니다."
                  }
                  btnText={"확인"}
                  bottomText={
                    <InquiryModalBottomText
                      onClick={() => {
                        window.open(`http://pf.kakao.com/_nfxfexj/chat`);
                      }}
                    >
                      문의 바로가기
                    </InquiryModalBottomText>
                  }
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else {
    return <Error />;
  }
};

export default Present;

const InquiryText = styled.div`
  margin-top: 26px;
  font-weight: 400;
  font-size: 12px;
  color: rgba(28, 31, 41, 0.8);
  text-decoration: underline;
  text-underline-position: under;
  cursor: pointer;
`;

const InquiryModalBottomText = styled.div`
  height: 32px;
  font-weight: 400;
  font-size: 12px;
  color: rgba(28, 31, 41, 0.8);
  text-decoration: underline;
  text-underline-position: under;
  cursor: pointer;
  text-align: center;
`;
