import React from "react";
import styles from "./Home.module.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";

const BtnView = () => (
  <button className={styles.info_check_btn}>
    <div className={styles.txt_section}>
      <div className={styles.new}>NEW</div>
      <div className={styles.check_txt}>사내추천 정책 및 보상금 확인하기</div>
    </div>
    <ArrowForwardIosIcon className={styles.arrow_icon} fontSize="inherit" />
  </button>
);

const CompensationBtn = ({
  company,
  mode,
  policyUrl,
}: {
  company?: string;
  mode?: boolean;
  policyUrl?: string;
}) => {
  if (mode) {
    return (
      <Link
        to={"/worker/invite/home/compensation/" + company}
        className={styles.link_tag}
      >
        <BtnView />
      </Link>
    );
  } else {
    return (
      <a href={policyUrl} target="blank" className={styles.link_tag}>
        <BtnView />
      </a>
    );
  }
};

export default CompensationBtn;
