import { useNavigate, useParams } from "react-router-dom";
import styles from "./Invitation.module.css";
import invite_gift from "../../../assets/invite_gift.png";
import { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../../common/Loading";
import Error from "../../../common/Error";
import { useApi } from "../../../hooks";
import classNames from "classnames";

const Invitation = () => {
  const { petchLog } = useApi();
  const navigate = useNavigate();
  const { invitationAccessToken } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [inviteeAccessToken, setInviteeAccessToken] = useState("");
  const [message, setMessage] = useState("당신을 우리회사에 추천하고 싶어요~!");
  const [company, setCompany] = useState("");
  const [inviter, setInviter] = useState("");
  const [expiredDays, setExpiredDays] = useState(0);
  const [isExpiredPage, setIsExpiredPage] = useState(false);
  const [isAlreadyDonePage, setIsAlreadyDonePage] = useState(false);

  const onClickEnter = () => {
    petchLog({ step: "enter", inviteeCode: inviteeAccessToken });
    navigate(
      `/seeker/submit/personal_info/${invitationAccessToken}/${inviteeAccessToken}`,
      {
        state: {
          company,
        },
      }
    );
  };

  useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${invitationAccessToken}` },
    };
    axios
      .get(`/invitations/${invitationAccessToken}`, config)
      .then((res) => {
        const { invitee, message, company, inviter, expiredDays } = res.data;
        setInviteeAccessToken(invitee);
        if (message) setMessage(message);
        setCompany(company);
        setInviter(inviter);
        setExpiredDays(expiredDays);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        switch (e.response.status) {
          case 410:
            setIsExpiredPage(true);
            return;
          case 409:
            setIsAlreadyDonePage(true);
            return;
          default:
            setApiError(true);
            return;
        }
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : isExpiredPage ? (
        <Error isAlreadyDone={false} />
      ) : isAlreadyDonePage ? (
        <Error isAlreadyDone={true} />
      ) : apiError ? (
        <Error />
      ) : (
        <div className={styles.background}>
          <div className={styles.content}>
            <div className={styles.top}>
              <div className={styles.title}>
                <div className={styles.highlight}>{company}</div>
                <div>의</div>
              </div>
              <span className={classNames(styles.title, styles.inline_title)}>
                <span>소중한 임직원</span>
                <span className={styles.highlight}>{inviter}</span>
                <span>님으로부터</span>
              </span>
              <div className={styles.title}>사내추천을 받았어요.</div>
            </div>
            <div className={styles.invite}>
              <img
                className={styles.gift_img}
                alt="invite_gift"
                src={invite_gift}
              />
              <div className={styles.message_area}>
                <div className={styles.coffeechat_invite}>
                  COFFEECHAT INVITE
                </div>
                <div className={styles.message_sub_title}>
                  {company} 사내추천으로 초대 받았어요!
                </div>
                <div className={styles.message_title}>
                  부담없이 커피챗 어떠세요?
                </div>
                <div className={styles.personal_message}>{message}</div>
              </div>
            </div>
            <div className={styles.button_space} />

            <button className={styles.input_deadline}>
              <span>
                <span>입력 기한이</span>
                <span className={styles.input_date}>{expiredDays}일</span>
                <span>남았습니다.</span>
              </span>
            </button>
            <button className={styles.friend_invite_btn} onClick={onClickEnter}>
              정보 입력하고 커피챗 제안받기
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Invitation;
