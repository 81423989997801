import React, { useMemo, useState } from "react";
import classNames from "classnames";
import styles from "./MyRecommendation.module.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../common/Loading";
import Error from "../../../../common/Error";
import { useQuery } from "react-query";
import { useApi } from "../../../../hooks";
import RecommandList from "./RecommendList";
import SimpleDialog from "../../../../common/SimpleDialog";

type CardStatus = "추천 대기" | "응답 대기" | "추천 완료";

const COMMENT = {
  default: {
    title: "",
    desc: "",
  },
  hold: {
    title: "추천 대기",
    desc: "추천한 분의 정보등록이 완료되었으나, 내가 아직 코멘트를 작성하지 않은 경우 표기됩니다.",
  },
  complete: {
    title: "추천 완료",
    desc: "추천한 분이 정보등록이 완료하여, 내가 코멘트 작성까지 마친 경우 추천 완료에 표기됩니다.",
  },
};

const MyRecommendation = () => {
  const navigate = useNavigate();
  const { getAutho } = useApi();
  const [modal, setModal] = useState<{ title: string; desc: string }>(
    COMMENT.default
  );

  const { data, isError, isLoading } = useQuery("user", getAutho);

  const onClickInvite = () => {
    navigate(`/worker/invite/home`);
  };

  const onClickInviteFriend = () => {
    navigate(`/worker/invite/present`);
  };

  const modalOpen = (state?: CardStatus) => {
    if (state === "추천 대기") {
      setModal(COMMENT.hold);
    } else if (state === "추천 완료") {
      setModal(COMMENT.complete);
    } else {
      setModal(COMMENT.default);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <Error />
      ) : (
        <div className={styles.background}>
          <SimpleDialog
            onConfirm={() => setModal(COMMENT.default)}
            open={modal.title === "" ? false : true}
            title={modal.title}
            desc={modal.desc}
            btnText={"확인"}
          />
          <div>
            <div className={styles.content}>
              <div className={styles.mobile_background}>
                <div className={styles.center}>
                  <div className={styles.center_btn}>
                    <div className={styles.button_wrap}>
                      <button
                        className={classNames(
                          styles.menu_btn,
                          styles.unactive_btn
                        )}
                        onClick={onClickInvite}
                      >
                        초대하기
                      </button>
                      <button
                        className={classNames(
                          styles.menu_btn,
                          styles.active_btn
                        )}
                      >
                        나의추천
                      </button>
                    </div>
                  </div>
                  <div className={styles.name}>
                    {data.company.name} {data.name} 님
                  </div>
                  <div className={styles.dashboard}>
                    <div
                      className={classNames(
                        styles.row,
                        styles.border_bottom,
                        styles.padding_bottom
                      )}
                    >
                      <div
                        className={classNames(
                          styles.block,
                          styles.border_right
                        )}
                      >
                        <div
                          className={classNames(
                            styles.block_name,
                            styles.block_underline
                          )}
                          onClick={() => modalOpen("추천 대기")}
                        >
                          추천 대기
                        </div>
                        <div className={styles.block_value}>
                          {data.waitingRecommendCount}명
                        </div>
                      </div>
                      <div
                        className={classNames(
                          styles.block,
                          styles.border_right
                        )}
                      >
                        <div
                          className={classNames(
                            styles.block_name,
                            styles.block_underline
                          )}
                          onClick={() => modalOpen("추천 완료")}
                        >
                          추천 완료
                        </div>
                        <div className={styles.block_value}>
                          {data.finishRecommendCount}명
                        </div>
                      </div>
                      <div className={styles.block}>
                        <div className={styles.block_name}>채용</div>
                        <div className={styles.block_value}>
                          {data.hireCount}명
                        </div>
                      </div>
                    </div>
                    <div className={classNames(styles.row, styles.padding_top)}>
                      <div
                        className={classNames(
                          styles.block,
                          styles.border_right
                        )}
                      >
                        <div className={styles.block_name}>나의 보상금</div>
                        <div className={styles.block_value}>
                          {data.reward.toLocaleString("ko-KR")}원
                        </div>
                      </div>
                      <div className={styles.block}>
                        <div className={styles.block_name}>사내 추천 랭킹</div>
                        <div className={styles.block_value}>#{data.rank}</div>
                      </div>
                    </div>
                  </div>
                  <button
                    className={styles.frined_invite_btn}
                    disabled={!data.invitationCount}
                    onClick={onClickInviteFriend}
                  >
                    <div className={styles.invite_btn_wrap}>
                      <div className={styles.given_invitation}>
                        내가 보유한 초대장 {data.invitationCount}장
                      </div>
                      {data.invitationCount ? (
                        <div className={styles.icon_wrap}>
                          <div>내 친구 초대하기</div>
                          <ArrowForwardIosIcon
                            className={styles.arrow_icon}
                            style={{ fontSize: "16px" }}
                          />
                        </div>
                      ) : (
                        <div className={styles.see_you_first}>
                          다음달 1일에 다시 생겨요
                        </div>
                      )}
                    </div>
                  </button>
                  <div className={styles.bottom}></div>
                </div>
              </div>
              <div className={styles.bottom}>
                <RecommandList labelClick={modalOpen} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyRecommendation;
