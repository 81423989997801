import axios from "axios";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Default from "./common/Default";
import Error from "./common/Error";
import ScrollToTop from "./common/ScrollToTop";
import Invitation from "./components/seeker/intro/Invitation";
import Done from "./components/seeker/submit/Done";
import PersonalInfo from "./components/seeker/submit/PersonalInfo";
import Comment from "./components/worker/invite/Comment";
import Complete from "./components/worker/invite/Complete";
import Compensation from "./components/worker/invite/Compensation";
import Home from "./components/worker/invite/Home";
import MyRecommendation from "./components/worker/invite/MyRecommendation";
import Present from "./components/worker/invite/Present";
import PresentConfirm from "./components/worker/invite/PresentConfirm";
import EmailAuth from "./components/worker/login/EmailAuth";
import Enter from "./components/worker/login/Enter";

function App() {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/company/:company" element={<Enter />} />
          <Route
            path="/worker/login/email_auth/:company"
            element={<EmailAuth mode="home" />}
          />
          <Route path="/worker/invite/home" element={<Home />} />
          <Route
            path="/worker/invite/home/compensation/:company"
            element={<Compensation />}
          />
          <Route path="/worker/invite/present" element={<Present />} />
          <Route
            path="/worker/invite/present_confirm"
            element={<PresentConfirm />}
          />
          <Route path="/worker/invite/complete" element={<Complete />} />
          <Route
            path="/worker/invite/my_recommendation/"
            element={<MyRecommendation />}
          />
          <Route
            path="/worker/invite/comment/:id/:inviteid"
            element={<Comment />}
          />
          <Route
            path="/worker/invite/comment/email_auth/:company/:id/:inviteid"
            element={<EmailAuth mode="comment" />}
          />
          <Route
            path="/worker/invite/comment/recommend"
            element={<Error isAlreadyDone />}
          />
          <Route path="/worker/invite/comment/complete" element={<Done />} />
          <Route
            path="/seeker/intro/invitation/:invitationAccessToken"
            element={<Invitation />}
          />
          <Route
            path="/seeker/submit/personal_info/:invitationAccessToken/:inviteeAccessToken"
            element={<PersonalInfo />}
          />
          <Route path="/404" element={<Error />} />
          <Route path="/seeker/submit/done" element={<Done />} />
          <Route path="*" element={<Default />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
