import { ReactElement } from "react";
import { useMediaQuery } from 'react-responsive';
import { SyncLoader } from "react-spinners";
import styles from "./Loading.module.css";

const Loading = () => {
    return (
    <div className={styles.background}>
        <>
            <div className={styles.content} >
                <SyncLoader color="#5E67EB"/>
            </div>
        </>
    </div>
    )
  }

export default Loading