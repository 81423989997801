import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import styles from "./Enter.module.css";
import Loading from "../../../common/Loading";
import { useQuery } from "react-query";
import { useApi } from "../../../hooks";

const Enter = () => {
  const { company } = useParams();
  const { getCompany } = useApi();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery("company", () => getCompany(company), {
    onError() {
      navigate("/404");
    },
  });

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.background}>
          <div className={styles.content}>
            <div className={styles.center}>
              <div className={styles.title}>
                <div>멋진 사람이</div>
                <div>멋진 사람을</div>
              </div>
              <img className={styles.logo} alt="logo_image" src={data.logo} />
              <div className={styles.sub_title}>
                <div>우리 회사의</div>
                <div>리크루터가 되어 주세요</div>
              </div>
            </div>
            <div className={styles.bottom}>
              <Link to={`/worker/login/email_auth/${company}`}>
                <button className={styles.enter_btn}>
                  지금 입장하고 초대장 받기
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Enter;
