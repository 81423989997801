import styles from "./Done.module.css";
import invite_enter from "../../../assets/invite_enter.png"

const Done = () => {
    const title = '제출이 완료되었습니다.'
    const subTitle = '인사 담당자가 빠르게 검토 후 커피챗 제안 안내를 드릴 예정입니다.'

    return (
    <div className={styles.background}>
        <>
            <div className={styles.content} >
                <div className={styles.title}>{title}</div>
                <div className={styles.sub_title}>{subTitle}</div>
                <img className={styles.logo} alt="invite_logo" src={invite_enter}/>
            </div>
        </>
    </div>
    )
}

export default Done;