import Dialog from "@mui/material/Dialog";
import classNames from "classnames";
import { FC } from "react";
import styles from "./SimpleDialog.module.css";

type Props = {
  onConfirm: () => void;
  open: boolean;
  title: string;
  desc: string;
  btnText: string;
  closeBtnText?: string;
  bottomText?: React.ReactNode;
  onClose?: () => void;
};

const SimpleDialog: FC<Props> = ({
  onConfirm,
  open,
  title,
  desc,
  btnText,
  closeBtnText,
  bottomText,
  onClose,
}) => {
  return (
    <Dialog
      open={open}
      BackdropProps={{ style: { backgroundColor: "rgba(28, 31, 41, 0.8)" } }}
      PaperProps={{
        style: {
          maxWidth: "350px",
          minWidth: "256px",
          borderRadius: "16px",
        },
      }}
    >
      <div className={styles.title}>{title}</div>
      <div className={styles.desc}>{desc}</div>
      <div className={styles.btn_wrap}>
        {closeBtnText && (
          <button
            className={classNames(
              styles.btn,
              closeBtnText && styles.double_btn_left
            )}
            onClick={onClose}
          >
            {closeBtnText}
          </button>
        )}
        <button
          className={classNames(
            styles.btn,
            closeBtnText && styles.double_btn_right
          )}
          onClick={onConfirm}
        >
          {btnText}
        </button>
      </div>
      {bottomText && (
        <>
          {bottomText}
          <div style={{ height: "16px" }} />
        </>
      )}
    </Dialog>
  );
};

export default SimpleDialog;
