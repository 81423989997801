import { FC } from "react";
import styles from "./Error.module.css";
import { useMediaQuery } from "react-responsive";

type Props = {
  isAlreadyDone?: boolean | undefined;
};

const Error = ({ isAlreadyDone }: { isAlreadyDone?: boolean }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 375px)" });
  let title = isAlreadyDone
    ? "이미 작성이 완료되었습니다."
    : "입력 기한이 마감되었어요!";
  let subTitle = isAlreadyDone
    ? "인사팀이 검토 후 제안 예정입니다."
    : "입력 기한이 지났습니다. 친구에게 초대장을 다시 요청해주세요.";
  if (isAlreadyDone === undefined) {
    title = "오류가 발생했습니다.";
    subTitle = "페이지 새로고침을 시도해 주세요.";
  }

  return (
    <div className={styles.background}>
      <>
        <div className={styles.content}>
          <div className={styles.title}>{title}</div>
          <div className={styles.sub_title}>{subTitle}</div>
        </div>
      </>
    </div>
  );
};

export default Error;
