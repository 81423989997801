import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { useApi } from "../../../../hooks";
import styles from "./MyRecommendation.module.css";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

type CardStatus = "추천 대기" | "응답 대기" | "추천 완료";

const COMMENT = {
  default: {
    title: "",
    desc: "",
  },
  hold: {
    title: "추천 대기",
    desc: "추천한 분의 정보등록이 완료되었으나, 내가 아직 코멘트를 작성하지 않은 경우 표기됩니다.",
  },
  complete: {
    title: "추천 완료",
    desc: "추천한 분이 정보등록이 완료하여, 내가 코멘트 작성까지 마친 경우 추천 완료에 표기됩니다.",
  },
};

const RecommandList = ({
  labelClick,
}: {
  labelClick: (value: CardStatus) => void;
}) => {
  const { getInviteeList, autho } = useApi();
  const { data } = useQuery("getInviteeList", getInviteeList);
  const navigate = useNavigate();

  const list = useMemo(() => {
    if (data) {
      const unOrderedData = data.slice();
      const orderedData = [
        ...unOrderedData.filter((d: any) => d.statusText === "추천 대기"),
        ...unOrderedData.filter((d: any) => d.statusText === "응답 대기"),
        ...unOrderedData.filter((d: any) => d.statusText === "추천 완료"),
      ];

      return orderedData;
    }

    return [];
  }, [data]);

  const onClickWriteComment = (id: string) => {
    navigate(`/worker/invite/comment/${autho.id}/${id}`);
  };
  const getDDay = (dday: number) => {
    return dday ? `D-${dday}` : "D-day";
  };
  const getInfoText = (status: CardStatus) => {
    if (status === "추천 대기") return "코멘트를 작성하고 추천을 완료해 주세요";
    else if (status === "응답 대기")
      return "추천받은 분이 아직 정보 등록을 하지 않았어요";
    else return "추천이 완료되어 인재풀에 등록되었어요";
  };

  return (
    <div className={styles.bottom}>
      <div className={styles.recommend_list}>
        {list.map((list: any, i: number) => (
          <div className={styles.card} key={"card" + i}>
            <div
              onClick={() => labelClick(list.statusText)}
              className={classNames(
                styles.status,
                list.statusText === "추천 완료" && styles.recommend_done,
                list.statusText === "추천 완료" && styles.recommend_hold,
                list.statusText === "추천 대기" && styles.recommend_hold
              )}
            >
              {list.statusText}
            </div>

            <div className={styles.detail}>
              <div className={styles.name_dday_wrap}>
                <div className={styles.card_name}>{list.name}</div>
                {list.statusText === "응답 대기" && (
                  <div className={styles.dday}>
                    {getDDay(list.expiredDays!)}
                  </div>
                )}
              </div>
              {list.statusText !== "응답 대기" && (
                <>
                  <div className={styles.detail_info}>{list.company}</div>
                  <div className={styles.detail_info}>
                    {list.job} | {list.years + "년차"}
                  </div>
                </>
              )}
              {list.statusText === "추천 대기" && (
                <button
                  className={styles.write_comment}
                  onClick={() => onClickWriteComment(list.id)}
                >
                  코멘트 작성하기
                </button>
              )}
              <div className={styles.info_text}>
                {getInfoText(list.statusText)}
              </div>
            </div>
          </div>
        ))}

        <div className={styles.bottom_info}>
          최근 6개월 이내 추천만 보여집니다.
        </div>
      </div>
    </div>
  );
};
export default RecommandList;
