import { IconButton, Input } from "@mui/material";
import classNames from "classnames";
import { useEffect, useState } from "react";
import styles from "./EmailAuth.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loading from "../../../common/Loading";
import Error from "../../../common/Error";

const EmailAuth = ({ mode }: { mode?: "home" | "comment" }) => {
  const navigate = useNavigate();
  const { company, inviteid, id } = useParams();
  const invalidEmail: string = "이메일 형식이 아닙니다.";
  const notWorkerEmail: string = "임직원 이메일이 아닙니다.";
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [getBtnDisable, setGetBtnDisable] = useState(true);
  const [errorMsg, setErrorMsg] = useState(invalidEmail);
  const [inputEmail, setInputEmail] = useState("");
  const [emailErrorVisible, setEmailErrorVisible] = useState(false);
  const [isAuthNumberDisplay, setIsAuthNumberDisplay] = useState(false);
  const [isWrongAuthNumber, setIsWrongAuthNumber] = useState(false);
  const [companyEmailFormat, setCompanyEmailFormat] = useState("");
  const enableDomain = [companyEmailFormat];
  const [sec, setSec] = useState(0);
  const [min, setMin] = useState(10);
  const onChangeEmail = (event: any): void => {
    const input = event.target.value.replaceAll(" ", "");
    setInputEmail(input);
    setGetBtnDisable(!input);
    setEmailErrorVisible(false);
  };

  const onChangeAuthNum = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const target = event.target.value;
    if (target.length === 6) {
      axios
        .post("/inviters/verify/email/confirm", {
          email: inputEmail,
          code: target,
        })
        .then((res) => {
          window.sessionStorage.setItem(
            "userInfo",
            JSON.stringify({ id: res.data.id, token: res.data.accessToken })
          );
          if (mode === "home") {
            navigate(`/worker/invite/home`);
          } else {
            navigate(`/worker/invite/comment/${id}/${inviteid}`);
          }
        })
        .catch((e) => {
          setIsWrongAuthNumber(true);
        });
    } else if (target.length > 6) {
      event.target.value = target.slice(1);
    } else {
      setIsWrongAuthNumber(false);
    }
  };

  const onClickBack = () => {
    navigate(-1);
  };
  const isValidEmail = (email: string) => {
    const emailRegExp =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    if (!emailRegExp.test(email)) {
      setErrorMsg(invalidEmail);
      return false;
    }
    const domain = email.split("@")[1];

    if (!enableDomain.includes(domain)) {
      setErrorMsg(notWorkerEmail);
      return false;
    }
    return true;
  };

  const onClickGetAuthEmail = () => {
    if (isValidEmail(inputEmail)) {
      setGetBtnDisable(true);
      if (!isAuthNumberDisplay) setIsAuthNumberDisplay(true);
      else {
        setMin(10);
        setSec(0);
      }
      axios
        .post("/inviters/verify/email/request", { email: inputEmail })
        .catch((e) => setApiError(true));
    } else {
      setEmailErrorVisible(true);
      setGetBtnDisable(true);
    }
  };

  useEffect(() => {
    if (isAuthNumberDisplay) {
      if (min === 9 && sec === 50 && getBtnDisable) {
        setGetBtnDisable(false);
      }
    }
  }, [isAuthNumberDisplay, min, sec, getBtnDisable]);

  useEffect(() => {
    if (isAuthNumberDisplay) {
      const countdown = setInterval(() => {
        if (sec > 0) {
          setSec(sec - 1);
        }
        if (sec === 0) {
          if (min === 0) {
            clearInterval(countdown);
          } else {
            setMin(min - 1);
            setSec(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(countdown);
      };
    }
  }, [min, sec, isAuthNumberDisplay, getBtnDisable]);

  useEffect(() => {
    axios
      .get(`/companies/name/${company}`)
      .then((res) => {
        setCompanyEmailFormat(res.data.emailFormat);
        setIsLoading(false);
      })
      .catch((e) => setApiError(true));
  }, [company]);

  const displayTime = (min: number, sec: number) => {
    const displayMin = min === 10 ? min : `0${min}`;
    const displaySec = sec > 9 ? sec : `0${sec}`;
    return `(${displayMin}:${displaySec})`;
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : apiError ? (
        <Error />
      ) : (
        <div className={styles.background}>
          <div className={styles.content}>
            <div className={styles.back_arrow}>
              <div
                style={{
                  visibility: mode === "comment" ? "hidden" : "inherit",
                }}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={mode === "home" ? () => onClickBack() : () => {}}
                >
                  <ArrowBackIcon />
                </IconButton>
              </div>
            </div>
            {!isAuthNumberDisplay && (
              <div className={styles.desc}>
                <div className={styles.title}>임직원 확인을 위해</div>
                <div className={styles.title}>
                  회사 메일 인증을 완료해주세요.
                </div>
                <div className={styles.title_desc}>
                  이메일은 확인을 위해서만 사용됩니다.
                </div>
              </div>
            )}
            <div className={styles.input_wrap}>
              <div
                className={classNames(
                  styles.input_name,
                  emailErrorVisible && styles.error
                )}
              >
                이메일 주소
              </div>
              <Input
                className={styles.input}
                autoCapitalize="none"
                placeholder="이메일 주소를 입력해주세요."
                fullWidth
                error={emailErrorVisible}
                onInput={onChangeEmail}
                readOnly={isAuthNumberDisplay}
                value={inputEmail}
                type="email"
              />
              <div
                className={classNames(
                  styles.input_error,
                  styles.error,
                  !emailErrorVisible && styles.visible_hidden
                )}
              >
                {errorMsg}
              </div>
            </div>
            <button
              className={classNames(
                styles.input_btn,
                getBtnDisable ? styles.disabled : styles.active
              )}
              disabled={getBtnDisable}
              onClick={onClickGetAuthEmail}
            >
              {isAuthNumberDisplay
                ? `인증 메일 다시 받기 ${displayTime(min, sec)}`
                : "인증 메일 받기"}
            </button>
            {!isAuthNumberDisplay && (
              <div className={styles.btm_desc}>
                회사 메일 계정으로 인증이 필요합니다.
              </div>
            )}
            {isAuthNumberDisplay && (
              <div className={styles.input_wrap}>
                <div
                  className={classNames(
                    styles.input_name,
                    isWrongAuthNumber && styles.error
                  )}
                >
                  인증번호
                </div>
                <Input
                  className={styles.input}
                  type="number"
                  placeholder="인증 번호 6자리"
                  fullWidth
                  error={isWrongAuthNumber}
                  onChange={onChangeAuthNum}
                />
                <div
                  className={classNames(
                    styles.input_error,
                    styles.error,
                    !isWrongAuthNumber && styles.visible_hidden
                  )}
                >
                  인증 번호가 일치하지 않습니다.
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default EmailAuth;
