import { useMemo } from "react";
import axios from "axios";

const useApi = () => {
  const autho = useMemo(() => {
    const user = window.sessionStorage.getItem("userInfo")
      ? window.sessionStorage.getItem("userInfo")!
      : "{}";
    if (user) {
      const userAuto = JSON.parse(user);
      return { token: userAuto.token, id: userAuto.id };
    } else {
      return {};
    }
  }, []);

  const getCompany = async (company?: string) => {
    const res = await axios.get(`/companies/name/${company}`);
    return res.data;
  };

  const getAutho = async () => {
    const config = { headers: { Authorization: `Bearer ${autho.token}` } };
    const res = await axios.get("/inviters/me", config);
    return res.data;
  };

  const getInviteeList = async () => {
    const config = { headers: { Authorization: `Bearer ${autho.token}` } };

    const res = await axios.get("/inviters/invitees", config);
    return res.data;
  };

  const getInvitee = async (id?: string, inviteId?: string) => {
    const config = {
      headers: { Authorization: `Bearer ${id}` },
    };
    const res = await axios.get(`/inviters/invitees/${inviteId}`, config);

    return res.data;
  };

  const getInviteeRelation = async () => {
    const res = await axios.get("/inviters/selectItems/inviterRelation");
    return res.data;
  };

  const petchLog = async ({
    step,
    inviteeCode,
  }: {
    step: string;
    inviteeCode: string;
  }) => {
    const config = { headers: { Authorization: `Bearer ${inviteeCode}` } };

    const res = await axios.patch(
      "/Invitees/log",
      {
        stage: step,
      },
      config
    );
    return res.data;
  };

  return {
    autho,
    getAutho,
    getInviteeList,
    getInvitee,
    getCompany,
    getInviteeRelation,
    petchLog,
  };
};

export default useApi;
