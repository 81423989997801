import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import styles from "./Comment.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SimpleDialog from "../../../common/SimpleDialog";
import BottomSheet from "../../../common/BottomSheet";
import { useNavigate, useParams } from "react-router-dom";
import smile_emoji from "../../../assets/smile_emoji.png";
import blush_emoji from "../../../assets/blush_emoji.png";
import heart_eye_emoji from "../../../assets/heart_eye_emoji.png";
import axios from "axios";
import Loading from "../../../common/Loading";
import Error from "../../../common/Error";
import { useQuery } from "react-query";
import { useApi } from "../../../hooks";

export type FitType = "checkHR" | "good" | "best" | undefined;

const Comment = () => {
  const navigate = useNavigate();
  const { getInvitee, getInviteeRelation, autho } = useApi();
  const { inviteid, id } = useParams();
  const relationInputRef = useRef<HTMLInputElement>(null);
  const onClickBack = () => {
    setOpenBackModal(true);
  };
  const [message, setMessage] = useState("");
  const defaultDrawer = "나와의 관계를 선택해주세요.";
  const modalTitle = "필수 정보가 입력되지 않았습니다.";
  const modalDsc = "필수 정보를 입력해주세요.";
  const modalBtnTxt = "확인";
  const [drawerText, setDrawerText] = useState<string>(defaultDrawer);
  const [fitType, setFitType] = useState<FitType>(undefined);
  const [relation, SetRelation] = useState<string>("");
  const [openBtmSheet, setOpenBtmSheet] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openBackModal, setOpenBackModal] = useState(false);
  const [fitError, setFitError] = useState(false);
  const [relError, setRelError] = useState(false);
  const [reasonError, setReasonError] = useState(false);

  const [relationList, setRelationList] = useState([]);

  const {
    data: invitee,
    isLoading,
    isError,
  } = useQuery("getInvitee", () => getInvitee(id, inviteid), {
    onSuccess(data) {
      if (data.status != "WAITING_RECOMMEND") {
        navigate(`/worker/invite/comment/recommend`, {
          replace: true,
        });
      } else {
        if (!autho.token) {
          navigate(
            `/worker/invite/comment/email_auth/${data.inviterCompany}/${id}/${inviteid}`
          );
        }
      }
    },
  });

  useQuery("relation", getInviteeRelation, {
    onSuccess(data) {
      setRelationList(data.map((d: any) => d.text));
    },
  });

  const onChangeMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };
  const onSetRelation = (rel: string) => {
    SetRelation(rel);
    setDrawerText(rel);
    setOpenBtmSheet(false);
  };

  const getFitTypeText = (fit: FitType) => {
    switch (fit) {
      case "checkHR":
        return "인사팀이 확인해 주세요";
      case "good":
        return "잘 어울릴 것 같아요";
      case "best":
        return "회사에 꼭맞는 능력자에요";
    }
  };

  const getRelation = (rel: string) => {
    if (rel === "기타 (직접 작성할래요)") {
      return relationInputRef.current?.value;
    } else return rel;
  };

  const onClickComplete = () => {
    const isFitError = !fitType;
    const isRelError =
      !relation ||
      (relation === "기타 (직접 작성할래요)" &&
        !relationInputRef.current?.value);
    const isReasonError = !message;

    setFitError(isFitError);
    setRelError(isRelError);
    setReasonError(isReasonError);

    if (isFitError || isRelError || isReasonError) setOpenModal(true);
    else {
      const config = {
        headers: { Authorization: `Bearer ${id}` },
      };
      axios
        .patch(
          `/inviters/invitees/${inviteid}`,
          {
            companyFit: getFitTypeText(fitType),
            inviterRelation: getRelation(relation),
            recommendReason: message,
          },
          config
        )
        .then((res) => {
          if (autho.token) {
            navigate(`/worker/invite/my_recommendation`, {
              replace: true,
            });
          } else {
            navigate(`/worker/invite/comment/complete`, {
              replace: true,
            });
          }
        });
      // .catch((e) => setApiError(true));
    }
  };

  const onExit = () => {
    setOpenBackModal(false);
    navigate(`/worker/invite/my_recommendation`);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <Error />
      ) : (
        <div className={styles.viewport}>
          <div className={styles.background}>
            <div className={styles.info}>
              <div className={styles.side_padding}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={onClickBack}
                >
                  <ArrowBackIcon />
                </IconButton>
                <div className={styles.name}>{invitee.name}</div>
                <div className={styles.company}>{invitee.company}</div>
                <div className={styles.job_info}>
                  {invitee.job} | {invitee.years.toString()}년차
                </div>
              </div>
            </div>
            <div className={styles.survey}>
              <div className={styles.side_padding}>
                <div
                  className={classNames(styles.question, styles.bottom_split)}
                >
                  <div className={styles.question_info}>
                    <div
                      className={classNames(
                        styles.question_title,
                        fitError && styles.error
                      )}
                    >
                      우리 회사 Fit
                    </div>
                    <div className={styles.essintial}>필수</div>
                  </div>
                  <div className={styles.question_desc}>
                    추천하는 분과 우리회사의 적합도를 어떻게 생각하시나요?
                  </div>
                  <div className={styles.choice_set}>
                    <button
                      className={classNames(
                        styles.choice_btn,
                        fitType === "checkHR" && styles.selected_fit
                      )}
                      onClick={() => setFitType("checkHR")}
                    >
                      <div className={styles.btn_wrap}>
                        <img
                          className={styles.emoji}
                          alt="smile_emoji"
                          src={smile_emoji}
                        />
                        <div className={styles.btn_text}>인사팀이</div>
                        <div className={styles.btn_text}>확인해주세요</div>
                      </div>
                    </button>
                    <button
                      className={classNames(
                        styles.choice_btn,
                        styles.center_btn,
                        fitType === "good" && styles.selected_fit
                      )}
                      onClick={() => setFitType("good")}
                    >
                      <div className={styles.btn_wrap}>
                        <img
                          className={styles.emoji}
                          alt="blush_emoji"
                          src={blush_emoji}
                        />
                        <div className={styles.btn_text}>잘 어울릴 것</div>
                        <div className={styles.btn_text}>같아요</div>
                      </div>
                    </button>
                    <button
                      className={classNames(
                        styles.choice_btn,
                        fitType === "best" && styles.selected_fit
                      )}
                      onClick={() => setFitType("best")}
                    >
                      <div className={styles.btn_wrap}>
                        <img
                          className={styles.emoji}
                          alt="heart_eye_emoji"
                          src={heart_eye_emoji}
                        />
                        <div className={styles.btn_text}>회사에 꼭맞는</div>
                        <div className={styles.btn_text}>능력자에요</div>
                      </div>
                    </button>
                  </div>
                </div>
                <div className={styles.question}>
                  <div className={styles.question_info}>
                    <div
                      className={classNames(
                        styles.question_title,
                        styles.my_relation,
                        relError && styles.error
                      )}
                    >
                      나와의 관계
                    </div>
                    <div className={styles.essintial}>필수</div>
                  </div>
                  <button
                    className={classNames(
                      styles.drawer_btn,
                      relError && styles.border_error
                    )}
                    onClick={() => setOpenBtmSheet(true)}
                  >
                    <div className={styles.drawer_wrap}>
                      <div
                        className={classNames(
                          styles.drawer_text,
                          relation && styles.drawer_chosen
                        )}
                      >
                        {drawerText}
                      </div>
                      <ExpandMoreIcon className={styles.arrow_icon} />
                    </div>
                  </button>
                  {relation === "기타 (직접 작성할래요)" && (
                    <input
                      className={classNames(
                        styles.relationship_input,
                        relError && styles.border_error
                      )}
                      placeholder="나와의 관계를 입력해 주세요"
                      ref={relationInputRef}
                    ></input>
                  )}
                </div>
                <div className={styles.question}>
                  <div className={styles.question_info}>
                    <div
                      className={classNames(
                        styles.question_title,
                        reasonError && styles.error
                      )}
                    >
                      추천 이유
                    </div>
                    <div className={styles.essintial}>필수</div>
                  </div>

                  <div
                    className={classNames(
                      styles.text_background,
                      reasonError && styles.mesesage_error
                    )}
                  >
                    <textarea
                      className={styles.message_input}
                      placeholder="추천하시는 이유를 구체적으로 작성해 주세요."
                      onChange={onChangeMessage}
                      maxLength={1000}
                      value={message}
                    ></textarea>
                    <div className={styles.message_length}>
                      {message.length.toLocaleString("ko-KR")}/1,000
                    </div>
                  </div>
                </div>
                <div className={styles.button_space}></div>
              </div>
            </div>
            =
            <div className={styles.bottom_button}>
              <div className={styles.gradation_background} />
              <div className={styles.solid_background}>
                <button className={styles.complete} onClick={onClickComplete}>
                  완료
                </button>
              </div>
            </div>
            <BottomSheet
              open={openBtmSheet}
              title="관계 선택"
              list={relationList}
              onClickOption={onSetRelation}
              selectedOption={relation}
              onCloseBottomSheet={() => setOpenBtmSheet(false)}
              maxHeight="70%"
            ></BottomSheet>
            <SimpleDialog
              onConfirm={() => setOpenModal(false)}
              open={openModal}
              title={modalTitle}
              desc={modalDsc}
              btnText={modalBtnTxt}
            ></SimpleDialog>
            <SimpleDialog
              onConfirm={() => setOpenBackModal(false)}
              open={openBackModal}
              title={"추천이 완료되지 않았습니다. 정말 그만할까요?"}
              desc={"코멘트를 남기면 추천이 완료됩니다."}
              btnText={"돌아가기"}
              closeBtnText={"나가기"}
              onClose={onExit}
            ></SimpleDialog>
          </div>
        </div>
      )}
    </>
  );
};

export default Comment;
