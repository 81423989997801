import React from "react";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./Common.module.css";

const BackHeader = ({ onClickBack }: { onClickBack: () => void }) => (
  <div className={styles.back_arrow}>
    <IconButton
      color="inherit"
      aria-label="open drawer"
      edge="start"
      onClick={onClickBack}
    >
      <ArrowBackIcon />
    </IconButton>
  </div>
);

export default BackHeader;
