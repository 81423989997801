import { useMemo } from "react";
import classNames from "classnames";
import { useEffect, useRef } from "react";
import styles from "./Home.module.css";
import { useNavigate } from "react-router-dom";
import invite_enter from "../../../../assets/invite_enter.png";
import Loading from "../../../../common/Loading";
import Error from "../../../../common/Error";
import { useQuery } from "react-query";
import { useApi } from "../../../../hooks";
import CompensationBtn from "./CompensationBtn";

const Home = () => {
  const navigate = useNavigate();
  const wholeRef = useRef<HTMLDivElement>(null); // 메시지 엘리먼트를 저장
  const { getAutho } = useApi();

  const { isLoading, isError, data } = useQuery("user", getAutho, {
    onError(error) {
      console.log(error);
      navigate("/worker");
    },
  });

  const onClickCheckInvite = () => {
    // wholeRef.current!.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    window.scrollTo({ behavior: "smooth", top: 999999999 });
  };
  const onClickMyRecommend = () => {
    navigate(`/worker/invite/my_recommendation`);
  };

  const onClickInviteFriend = () => {
    navigate(`/worker/invite/present`);
  };

  useEffect(() => {
    if (!isLoading && !isError)
      wholeRef.current!.scrollIntoView({ block: "start", inline: "nearest" });
  }, [isLoading, isError]);

  const ImageMode = useMemo(() => {
    if (data) {
      const policyUrlSlice = data.company?.policyUrl.slice(
        -3,
        data.company.policyUrl.length
      );
      if (policyUrlSlice === "png" || policyUrlSlice === "jpg") {
        return "img";
      } else {
        return "link";
      }
    } else {
      return "";
    }
  }, [data]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <Error />
      ) : (
        <div className={styles.background}>
          <div>
            <div
              className={classNames(
                styles.upper_content,
                styles.content_space_between
              )}
              ref={wholeRef}
            >
              <div className={styles.center}>
                <div className={styles.button_wrap}>
                  <button
                    className={classNames(styles.menu_btn, styles.active_btn)}
                  >
                    초대하기
                  </button>
                  <button
                    className={classNames(styles.menu_btn, styles.unactive_btn)}
                    onClick={onClickMyRecommend}
                  >
                    나의추천
                  </button>
                </div>
                <div
                  className={classNames(styles.sub_title, styles.top_position)}
                >
                  {data.introTextItems[0]}
                </div>
                <div className={styles.main_title}>
                  {data.introTextItems[1]}
                </div>
                <img
                  className={styles.logo}
                  alt="invite_logo"
                  src={invite_enter}
                />
                <div className={styles.invite_info}>
                  <div>{data.name} 님에게 </div>
                  <div className={styles.invite_bold}>
                    초대장 {data.invitationCount}장
                  </div>
                  <div>이 있어요.</div>
                </div>
              </div>
              <div className={styles.bottom}>
                <button
                  className={classNames(
                    styles.check_invite,
                    !data.invitationCount && styles.no_invitation_disabled
                  )}
                  onClick={onClickCheckInvite}
                >
                  <div className={styles.div_wrap}>
                    <div>내가 보유한 초대권</div>
                    <div className={styles.invite_count}>
                      {data.invitationCount}장
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.inner_content}>
                <div
                  className={classNames(styles.main_title, styles.top_position)}
                >
                  우리 회사에 추천하고 싶은 능력자 지인
                </div>
                <div className={styles.main_title}>이 있으신가요?</div>
                <CompensationBtn
                  company={data.company.name}
                  mode={data.company.isPolicyImageUrl}
                  policyUrl={data.company.policyUrl}
                />
                <div className={styles.people_list}>
                  <div className={styles.people_title}>
                    지금 우리회사가 찾고 있는 사람은
                  </div>
                  <div className={styles.people_scroll}>
                    <ul className={styles.people_ul}>
                      {data.company.positionItems.map(
                        (
                          item: {
                            job: string;
                            years: string;
                            url: string | null;
                          },
                          i: number
                        ) => {
                          console.log(item.url);
                          return (
                            <li
                              className={styles.people_li}
                              key={"job_list" + i}
                            >
                              {item.url ? (
                                <a
                                  href={item.url}
                                  target="blank"
                                  className={styles.job_year_wrap}
                                >
                                  <span className={styles.job}>{item.job}</span>
                                  <span className={styles.year}>
                                    {item.years}
                                  </span>
                                </a>
                              ) : (
                                <span className={styles.job_year_wrap}>
                                  <span className={styles.job}>{item.job}</span>
                                  <span className={styles.year}>
                                    {item.years}
                                  </span>
                                </span>
                              )}
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className={styles.bottom_button}>
                <div className={styles.gradation_background} />
                <div className={styles.solid_background}>
                  <button
                    className={classNames(
                      styles.friend_invite_btn,
                      !data.invitationCount && styles.no_invitation_disabled
                    )}
                    onClick={onClickInviteFriend}
                    disabled={!data.invitationCount}
                  >
                    {data.invitationCount
                      ? "내 친구 초대하기"
                      : "다음달 1일에 다시 생겨요"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
