import React, { useMemo, useState } from "react";
import styles from "./Compensation.module.css";
import BackHeader from "../../../../common/BackHeader";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useApi } from "../../../../hooks";

const Compensation = () => {
  const navigate = useNavigate();
  const { company } = useParams();
  const { getCompany } = useApi();

  const { data, isLoading } = useQuery("company", () => getCompany(company), {
    onError() {
      navigate("/404");
    },
  });

  const onClickBack = () => {
    navigate(`/worker/invite/home`);
  };
  return (
    <div className={styles.background}>
      <div className={styles.content}>
        <BackHeader onClickBack={onClickBack} />
        <div>
          <img src={data?.policyUrl} alt="" style={{ width: "100%" }} />
        </div>
      </div>
    </div>
  );
};

export default Compensation;
