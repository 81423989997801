import classNames from "classnames";
import styled from "@emotion/styled";

import BottomSheet from "../../../common/BottomSheet";
import SimpleDialog from "../../../common/SimpleDialog";
import styles from "./PersonalInfo.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import consider_emoji from "../../../assets/consider_emoji.png";
import prepare_emoji from "../../../assets/prepare_emoji.png";
import finding_emoji from "../../../assets/finding_emoji.png";
import axios from "axios";
import Loading from "../../../common/Loading";
import Error from "../../../common/Error";
import Check from "../../../assets/check.svg";
import { useApi } from "../../../hooks";

type Education = "bachelor" | "master" | "doctorate" | "기타" | undefined;
type Will = "consider" | "preparing" | "finding" | undefined;
type bottomSheetData = {
  id: number;
  text: string;
};

const PersonalInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { petchLog } = useApi();

  const companyName = location.state?.company || "";

  const { invitationAccessToken, inviteeAccessToken } = useParams();
  const config = {
    headers: { Authorization: `Bearer ${inviteeAccessToken}` },
  };

  const viewportRef = useRef<HTMLDivElement>(null);

  const wordkingYearDefaultDrawer = "현재 연차를 선택해주세요";
  const gradDefaultDrawer = "졸업 연도를 선택해 주세요";
  const preferredPositionDefaultDrawer = "원하는 포지션을 선택해 주세요";

  const [jobYearList, setJobYearList] = useState<Array<bottomSheetData>>([]);
  const [gradYearList, setGradYearList] = useState<Array<bottomSheetData>>([]);
  const [positionList, setPositionList] = useState<Array<bottomSheetData>>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [step, setStep] = useState(0);
  const stepRef = useRef<number>(0);
  const [openJobYearBtmSheet, setOpenJobYearBtmSheet] = useState(false);
  const [openGradYearBtmSheet, setOpenGradYearBtmSheet] = useState(false);
  const [openPreferredPositionBtmSheet, setOpenPreferredPositionBtmSheet] =
    useState(false);
  const [workingYeardrawerText, workingYearSetDrawerText] = useState<string>(
    wordkingYearDefaultDrawer
  );
  const [gradDrawerText, setGradDrawerText] =
    useState<string>(gradDefaultDrawer);
  const [preferredPositionDrawerText, setPreferredPositionDrawerText] =
    useState<string>(preferredPositionDefaultDrawer);
  const [workingYear, setWorkingYear] = useState<string | undefined>(undefined);
  const [gradYear, setGradYear] = useState<string | undefined>(undefined);
  const [preferredPosition, setPreferredPosition] = useState<
    string | undefined
  >(undefined);
  const [incomplete, setIncomplete] = useState(true);
  const [backOpenModal, setBackOpenModal] = useState(false);
  const [invalidLinkOpenModal, setInvalidLinkOpenModal] = useState(false);
  const [submitOpenModal, setSubmitOpenModal] = useState(false);
  const [submitPhoneOpenModal, setSubmitPhoneOpenModal] = useState(false);
  const [name, setName] = useState("");
  const [job, setJob] = useState("");
  const [company, setCompany] = useState("");
  const [school, setSchool] = useState("");
  const [education, setEducation] = useState<Education>(undefined);
  const [will, setWill] = useState<Will>(undefined);
  const [resume, setResume] = useState("");
  const [portfolio, setPortfolio] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [github, setGithub] = useState("");
  const resumeRef = useRef<HTMLInputElement>(null);
  const portfolioRef = useRef<HTMLInputElement>(null);
  const linkedInRef = useRef<HTMLInputElement>(null);
  const githubRef = useRef<HTMLInputElement>(null);
  const [resumeError, setResumeError] = useState(false);
  const [portfolioError, setPortfolioError] = useState(false);
  const [linkedInError, setLinkedInError] = useState(false);
  const [githubError, setGithubError] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [placeholderData, setPlaceholderData] = useState("010-0000-0000");
  const [agreePrivacy, setAgreePrivacy] = useState(false);

  const filedTitle = useMemo(() => {
    switch (step) {
      case 0:
        return "기본 정보 / 경력 사항";
      case 1:
        return "학력 사항";
      case 2:
        return "추가 정보";
      case 3:
        return "연락처";
    }
  }, [step]);

  const essential = useMemo(() => {
    switch (step) {
      case 0:
      case 1:
      case 3:
        return "필수";
      case 2:
        return "선택 사항";
    }
  }, [step]);

  const top_title = useMemo(() => {
    switch (step) {
      case 0:
      case 1:
        return "내 정보 입력하기";
      case 2:
        return "추가 정보 입력하기";
      case 3:
        return "인사 담당자가 연락드릴 휴대전화 번호와 이메일 주소를 입력해 주세요.";
    }
  }, [step]);

  const top_desc = useMemo(() => {
    switch (step) {
      case 0:
      case 1:
        return "모든 정보는 보호되며, 인사담당자만 확인할 수 있습니다.";
      case 2:
        return "자세한 정보를 입력하고 더 빠르고 부담없이 커피챗 제안을 받아보세요.";
      case 3:
        return "모든 정보는 안전하게 전달됩니다.";
    }
  }, [step]);

  const onClickBack = (browserBack?: number) => {
    if (browserBack) {
      if (browserBack === 0) setBackOpenModal(true);
      else {
        setStep(browserBack - 1);
        stepRef.current = browserBack - 1;
      }
    } else {
      if (step) {
        setStep(step - 1);
        stepRef.current = step - 1;
      } else {
        setBackOpenModal(true);
      }
    }
  };

  const getDisplayEducation = (education: Education) => {
    switch (education) {
      case "bachelor":
        return "학사";
      case "master":
        return "석사";
      case "doctorate":
        return "박사";
      case "기타":
        return "기타";
    }
  };

  const getWill = (will: Will) => {
    switch (will) {
      case "consider":
        return "제안 시 고려";
      case "finding":
        return "이직 준비 중";
      case "preparing":
        return "적극 구직 중";
    }
  };

  const onExit = () => {
    setBackOpenModal(false);
    navigate(`/seeker/intro/invitation/${invitationAccessToken}`);
  };

  const regexTest = (val: string) => {
    const regex =
      /^(http|https):\/\/(?:www\.|(?!www))[\w#!:.?+=&%@!\-]+(\.[\w#!:.?+=&%@!\-]+)+(\/|\/([\w#!:.?+=&%@!\-\/]+))?$/gi;
    return regex.test(val);
  };

  const checkURLValidation = useCallback(() => {
    const resume = resumeRef.current?.value;
    const portfolio = portfolioRef.current?.value;
    const linkedIn = linkedInRef.current?.value;
    const github = githubRef.current?.value;

    setResume(resume || "");
    setPortfolio(portfolio || "");
    setLinkedIn(linkedIn || "");
    setGithub(github || "");

    const invalidResume = !!resume && !regexTest(resume);
    const invalidPorfolio = !!portfolio && !regexTest(portfolio);
    const invalidLinkedIn = !!linkedIn && !regexTest(linkedIn);
    const invalidGithub = !!github && !regexTest(github);

    setResumeError(invalidResume);
    setPortfolioError(invalidPorfolio);
    setLinkedInError(invalidLinkedIn);
    setGithubError(invalidGithub);

    return invalidResume || invalidPorfolio || invalidLinkedIn || invalidGithub;
  }, [resumeRef, portfolioRef, linkedInRef, githubRef]);

  const checkInvalidEssentialAndPhone = (phone: string) => {
    const phoneFormat = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
    return phoneFormat.test(phone);
  };

  const checkInvalidEssentialAndEmail = useCallback(() => {
    const regex =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    const invalidPhone = !phone;
    const invalidEmail = !email || !regex.test(email);
    const invalidPrivacy = !agreePrivacy;
    return invalidPhone || invalidEmail || invalidPrivacy;
  }, [phone, email, agreePrivacy]);

  const onClickNext = () => {
    if (step === 2 && checkURLValidation()) {
      setInvalidLinkOpenModal(true);
    } else if (step === 3 && !checkInvalidEssentialAndPhone(phone)) {
      setSubmitPhoneOpenModal(true);
    } else if (step === 3 && checkInvalidEssentialAndEmail()) {
      setSubmitOpenModal(true);
    } else if (step === 3) {
      const param = {
        name: name,
        phone: phone,
        email: email,
        job: job,
        company: company,
        lastDegree: getDisplayEducation(education),
        school: school,
        graduationYear: gradYear?.slice(0, -1),
        years: workingYear,
      };
      if (preferredPosition)
        Object.assign(param, { position: preferredPosition });
      if (will) Object.assign(param, { willingness: getWill(will) });
      if (resume) Object.assign(param, { resumeUrl: resume });
      if (portfolio) Object.assign(param, { portfolioUrl: portfolio });
      if (linkedIn) Object.assign(param, { linkedinUrl: linkedIn });
      if (github) Object.assign(param, { githubUrl: github });

      axios
        .patch("/invitees", param, config)
        .then((res) => {
          navigate("/seeker/submit/done");
        })
        .catch((e) => setApiError(true));
    } else {
      setStep(step + 1);
      if (inviteeAccessToken) {
        petchLog({ step: `view${step + 1}`, inviteeCode: inviteeAccessToken });
      }
      stepRef.current = step + 1;
    }
  };

  const onSetWorkingYear = (year: string) => {
    setWorkingYear(year);
    workingYearSetDrawerText(year);
    setOpenJobYearBtmSheet(false);
  };
  const onSetGradYear = (year: string) => {
    setGradYear(year);
    setGradDrawerText(year);
    setOpenGradYearBtmSheet(false);
  };

  const onSetPreferredPosition = (position: string) => {
    setPreferredPosition(position);
    setPreferredPositionDrawerText(position);
    setOpenPreferredPositionBtmSheet(false);
  };

  const onComeback = () => {
    setBackOpenModal(false);
  };

  const onClickConfirmInvalidLink = () => {
    setInvalidLinkOpenModal(false);
  };

  const onSubmit = () => {
    setSubmitOpenModal(false);
    setSubmitPhoneOpenModal(false);
  };

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    var regExp = /[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\ '\"\\(\=]/gi;
    const pureName = event.target.value;
    setName(pureName.replace(regExp, ""));
  };

  const purifyingString = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFunc: (s: string) => void
  ) => {
    var regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
    const pure = event.target.value;
    setFunc(pure.replace(regExp, ""));
  };

  const withoutBlankEmail = (event: any) => {
    setEmail(event.target.value.replace(" ", ""));
  };

  const onChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pureNumber = event.target.value.replace(/[^0-9]/g, "");
    const changedNumber = pureNumber
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/(\-{1,2})$/g, "");
    let changedPlaceholder = "";
    const defaultPlaceholder = "010-0000-0000";
    for (let i = 0; i < defaultPlaceholder.length; i++) {
      if (i < changedNumber.length) changedPlaceholder += changedNumber[i];
      else changedPlaceholder += defaultPlaceholder[i];
    }
    setPlaceholderData(changedPlaceholder);
    setPhone(changedNumber);
  };

  useEffect(() => {
    switch (step) {
      case 0:
        setIncomplete(!name || !job || !company || !workingYear);
        break;
      case 1:
        setIncomplete(!education || !school || !gradYear);
        break;
      case 2:
        break;
      case 3:
        setIncomplete(!phone || !email || !agreePrivacy);
        break;
    }
  }, [
    step,
    name,
    job,
    company,
    workingYear,
    education,
    school,
    gradYear,
    phone,
    email,
    agreePrivacy,
    resume,
    portfolio,
    linkedIn,
    github,
  ]);

  useEffect(() => {
    if (!isLoading && !apiError) viewportRef.current!.scrollTo({ top: 0 });
  }, [isLoading, apiError, step]);

  const popstateEvent = useCallback(
    (e: any) => {
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function (event) {
        window.history.go(1);
      };
      onClickBack(stepRef.current);
    },
    [step]
  );

  const scrollDisableWhenBottomSheet = useCallback(
    (e: Event) => {
      console.log(
        "오라",
        openJobYearBtmSheet,
        openGradYearBtmSheet,
        openPreferredPositionBtmSheet
      );
      if (
        openJobYearBtmSheet ||
        openGradYearBtmSheet ||
        openPreferredPositionBtmSheet
      ) {
        e.preventDefault();
        console.log("엥");
      }
    },
    [openJobYearBtmSheet, openGradYearBtmSheet, openPreferredPositionBtmSheet]
  );

  useEffect(() => {
    viewportRef.current?.addEventListener(
      "scroll",
      scrollDisableWhenBottomSheet
    );
    return () => {
      viewportRef.current?.removeEventListener(
        "scroll",
        scrollDisableWhenBottomSheet
      );
    };
  }, [
    openJobYearBtmSheet,
    openGradYearBtmSheet,
    openPreferredPositionBtmSheet,
  ]);

  useEffect(() => {
    window.addEventListener("popstate", popstateEvent);
    axios
      .get("/invitees/selectItems/years")
      .then((res) => {
        setJobYearList(res.data.map((d: bottomSheetData) => d.text));
        axios
          .get("/invitees/selectItems/graduationYear")
          .then((res) => {
            setGradYearList(res.data.map((d: bottomSheetData) => d.text));
            const config = {
              headers: { Authorization: `Bearer ${inviteeAccessToken}` },
            };
            axios
              .get("/invitees/selectItems/position", config)
              .then((res) => {
                setPositionList(res.data.map((d: bottomSheetData) => d.text));
                setIsLoading(false);
              })
              .catch((e) => {
                setIsLoading(false);
                setApiError(true);
              });
          })
          .catch((e) => {
            setIsLoading(false);
            setApiError(true);
          });
      })
      .catch((e) => {
        setIsLoading(false);
        setApiError(true);
      });

    return () => {
      window.removeEventListener("popstate", popstateEvent);
    };
  }, []);

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function (event) {
      window.history.go(1);
    };

    window.scrollTo({ left: 0, top: 0 });

    if (step === 2) {
      if (resumeRef.current) resumeRef.current.value = resume;
      if (portfolioRef.current) portfolioRef.current.value = portfolio;
      if (linkedInRef.current) linkedInRef.current.value = linkedIn;
      if (githubRef.current) githubRef.current.value = github;
    }
  }, [step]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : apiError ? (
        <Error />
      ) : (
        <div className={styles.viewport} ref={viewportRef}>
          <div className={styles.background}>
            <div className={styles.info}>
              <div className={styles.upper_wrapper}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={() => onClickBack()}
                >
                  <ArrowBackIcon />
                </IconButton>
                <div className={styles.top_title}>{top_title}</div>
                <div className={styles.top_desc}>{top_desc}</div>
              </div>
            </div>
            <div className={styles.personal_info}>
              <div className={styles.side_padding}>
                <div className={classNames(styles.question)}>
                  <div className={styles.field_info}>
                    <div className={styles.field_title}>{filedTitle}</div>
                    <div className={styles.essintial}>{essential}</div>
                  </div>
                  {step === 0 && (
                    <>
                      <div className={styles.question_title}>성함</div>
                      <input
                        className={styles.question_input}
                        placeholder="실명을 입력해주세요"
                        onChange={onChangeName}
                        value={name}
                      ></input>
                      <div className={styles.question_title}>직무</div>
                      <input
                        className={styles.question_input}
                        placeholder="직무를 입력해주세요"
                        onChange={(e) => purifyingString(e, setJob)}
                        value={job}
                      ></input>
                      <div className={styles.question_title}>경력</div>
                      <button
                        className={styles.drawer_btn}
                        onClick={() => setOpenJobYearBtmSheet(true)}
                      >
                        <div className={styles.drawer_wrap}>
                          <div
                            className={classNames(
                              styles.drawer_text,
                              workingYear && styles.drawer_chosen
                            )}
                          >
                            {workingYeardrawerText}
                          </div>
                          <ExpandMoreIcon className={styles.arrow_icon} />
                        </div>
                      </button>
                      <div className={styles.question_title}>회사명</div>
                      <input
                        className={classNames(
                          styles.question_input,
                          styles.company_name
                        )}
                        placeholder="재직 중인 회사명을 입력해 주세요"
                        onChange={(e) => purifyingString(e, setCompany)}
                        value={company}
                      ></input>
                    </>
                  )}
                  {step === 1 && (
                    <>
                      <div className={styles.question_title}>최종 학력</div>
                      <div className={styles.choice_set}>
                        <button
                          className={classNames(
                            styles.choice_btn,
                            education === "bachelor" && styles.selected
                          )}
                          onClick={() => setEducation("bachelor")}
                        >
                          학사
                        </button>
                        <button
                          className={classNames(
                            styles.choice_btn,
                            education === "master" && styles.selected
                          )}
                          onClick={() => setEducation("master")}
                        >
                          석사
                        </button>
                        <button
                          className={classNames(
                            styles.choice_btn,
                            education === "doctorate" && styles.selected
                          )}
                          onClick={() => setEducation("doctorate")}
                        >
                          박사
                        </button>
                        <button
                          className={classNames(
                            styles.choice_btn,
                            styles.end_btn,
                            education === "기타" && styles.selected
                          )}
                          onClick={() => setEducation("기타")}
                        >
                          기타
                        </button>
                      </div>
                      <div className={styles.question_title}>졸업 학교</div>
                      <input
                        className={styles.question_input}
                        placeholder="최종 졸업 학교명을 입력해주세요"
                        onChange={(e) => purifyingString(e, setSchool)}
                        value={school}
                      ></input>
                      <div className={styles.question_title}>졸업 연도</div>
                      <button
                        className={classNames(
                          styles.drawer_btn,
                          styles.bottom_margin
                        )}
                        onClick={() => setOpenGradYearBtmSheet(true)}
                      >
                        <div className={styles.drawer_wrap}>
                          <div
                            className={classNames(
                              styles.drawer_text,
                              gradYear && styles.drawer_chosen
                            )}
                          >
                            {gradDrawerText}
                          </div>
                          <ExpandMoreIcon className={styles.arrow_icon} />
                        </div>
                      </button>
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <div className={styles.question_title}>선호 포지션</div>
                      <button
                        className={styles.drawer_btn}
                        onClick={() => setOpenPreferredPositionBtmSheet(true)}
                      >
                        <div className={styles.drawer_wrap}>
                          <div
                            className={classNames(
                              styles.drawer_text,
                              preferredPosition && styles.drawer_chosen
                            )}
                          >
                            {preferredPositionDrawerText}
                          </div>
                          <ExpandMoreIcon className={styles.arrow_icon} />
                        </div>
                      </button>
                      <div className={styles.question_title}>이직 의지치</div>
                      <div
                        className={classNames(
                          styles.choice_set,
                          styles.bottom_split
                        )}
                      >
                        <button
                          className={classNames(
                            styles.choice_btn,
                            styles.adjust_height,
                            will === "consider" && styles.selected
                          )}
                          onClick={() => setWill("consider")}
                        >
                          <div className={styles.btn_wrap}>
                            <img
                              className={styles.emoji}
                              alt="consider_emoji"
                              src={consider_emoji}
                            />
                            <div className={styles.btn_text}>제안 시 고려</div>
                          </div>
                        </button>
                        <button
                          className={classNames(
                            styles.choice_btn,
                            styles.adjust_height,
                            will === "preparing" && styles.selected
                          )}
                          onClick={() => setWill("preparing")}
                        >
                          <div className={styles.btn_wrap}>
                            <img
                              className={styles.emoji}
                              alt="prepare_emoji"
                              src={prepare_emoji}
                            />
                            <div className={styles.btn_text}>이직 준비 중</div>
                          </div>
                        </button>
                        <button
                          className={classNames(
                            styles.choice_btn,
                            styles.adjust_height,
                            will === "finding" && styles.selected
                          )}
                          onClick={() => setWill("finding")}
                        >
                          <div className={styles.btn_wrap}>
                            <img
                              className={styles.emoji}
                              alt="finding_emoji"
                              src={finding_emoji}
                            />
                            <div className={styles.btn_text}>적극 구직 중</div>
                          </div>
                        </button>
                      </div>
                      <div className={styles.info_text}>
                        이력서 추가 시 더 빠르게 커피챗 제안을 드릴 수 있어요.
                      </div>
                      <div
                        className={classNames(
                          styles.question_title,
                          resumeError && styles.error
                        )}
                      >
                        이력서
                      </div>
                      <input
                        className={classNames(
                          styles.question_input,
                          resumeError && styles.error_border
                        )}
                        placeholder="https://"
                        ref={resumeRef}
                      ></input>
                      <div
                        className={classNames(
                          styles.question_title,
                          portfolioError && styles.error
                        )}
                      >
                        포트폴리오
                      </div>
                      <input
                        className={classNames(
                          styles.question_input,
                          portfolioError && styles.error_border
                        )}
                        placeholder="https://"
                        ref={portfolioRef}
                      ></input>
                      <div
                        className={classNames(
                          styles.question_title,
                          linkedInError && styles.error
                        )}
                      >
                        링크드인
                      </div>
                      <input
                        className={classNames(
                          styles.question_input,
                          linkedInError && styles.error_border
                        )}
                        placeholder="https://"
                        ref={linkedInRef}
                      ></input>
                      <div
                        className={classNames(
                          styles.question_title,
                          githubError && styles.error
                        )}
                      >
                        Github 계정
                      </div>
                      <input
                        className={classNames(
                          styles.question_input,
                          githubError && styles.error_border
                        )}
                        placeholder="https://"
                        ref={githubRef}
                      ></input>
                    </>
                  )}
                  {step === 3 && (
                    <>
                      <div className={styles.question_title}>휴대전화 번호</div>
                      <div className={styles.placeholder}>
                        <input
                          maxLength={13}
                          placeholder={placeholderData}
                          className={styles.question_input}
                          onChange={onChangePhoneNumber}
                          value={phone}
                          type="tel"
                        ></input>
                      </div>
                      <div className={styles.question_title}>이메일 주소</div>
                      <input
                        className={styles.question_input}
                        placeholder="email@gmail.com"
                        onInput={withoutBlankEmail}
                        value={email}
                        type="email"
                      />

                      <div className={styles.question_title}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          onClick={() => {
                            setAgreePrivacy(!agreePrivacy);
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <Input
                              type="checkbox"
                              readOnly
                              checked={agreePrivacy}
                            />
                            개인정보 수집 및 이용 동의
                          </div>
                          <div className={styles.essintial}>{essential}</div>
                        </div>
                      </div>
                      <div className={styles.question_terms}>
                        <div className={styles.question_terms_wrap}>
                          {`1. 개인정보 수집 항목
[필수]
    • 성명, 이메일, 전화번호, 학력, 경력
[선택]
    • 선호사항, 이력서, 포트폴리오, 기타 자기소개 계정

2. 개인정보 수집 및 이용 목적
    • 커피챗 진행, 영입 진행 단계별 결과 등 정보 안내 및 인재풀 구성
    • 영입 기록과 통계처리
    • 인재풀 관리 및 입사과정 개선에 활용

3. 개인정보 보유 및 이용 기간
    • 작성하신 개인정보는 회사의 인재영입을 위한 인재풀로 활용되며, 제출 1년 후까지 보관됩니다. 삭제를 요청하실 경우 해당 개인정보는 삭제됩니다.
                            `}
                        </div>
                      </div>

                      <div className={styles.question_title}>
                        개인정보 위탁 고지
                      </div>
                      <div className={styles.question_terms}>
                        <div className={styles.question_terms_wrap}>
                          {`${companyName}${getKoreanAffix(
                            companyName,
                            "은는"
                          )} 주식회사 커피챗에 사내추천 인재풀 관리 서비스 운영 및 관리에 관한 업무를 위탁하였으며, 해당 업무는 정보주체의 개인정보 처리를 포함하고 있습니다. 주식회사 커피챗은 위탁업무 수행과정에서 정보주체의 동의 없이 개인정보 처리업무를 재위탁 하지 않으며, ${companyName}(으)로부터 위임받은 개인정보 처리업무를 수행함에 있어 안정성 확보 및 관리 감독에 최선을 다합니다.

                            `}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className={styles.button_wrapper}>
                  <button
                    className={classNames(
                      styles.complete,
                      incomplete && styles.incomplete
                    )}
                    disabled={incomplete}
                    onClick={onClickNext}
                  >
                    {step === 3 ? "제출하기" : step === 2 ? "완료" : "다음"}
                  </button>
                </div>
              </div>
            </div>
            <BottomSheet
              open={openJobYearBtmSheet}
              title="경력"
              list={jobYearList}
              onClickOption={onSetWorkingYear}
              selectedOption={workingYear}
              onCloseBottomSheet={() => setOpenJobYearBtmSheet(false)}
              maxHeight="70%"
            />
            <BottomSheet
              open={openGradYearBtmSheet}
              title="졸업 연도"
              list={gradYearList}
              onClickOption={onSetGradYear}
              selectedOption={gradYear}
              onCloseBottomSheet={() => setOpenGradYearBtmSheet(false)}
              maxHeight="70%"
            />
            <BottomSheet
              open={openPreferredPositionBtmSheet}
              title="선호 포지션"
              list={positionList}
              onClickOption={onSetPreferredPosition}
              selectedOption={preferredPosition}
              onCloseBottomSheet={() => setOpenPreferredPositionBtmSheet(false)}
              maxHeight="70%"
            />
            <SimpleDialog
              onConfirm={onComeback}
              open={backOpenModal}
              title={"정보 등록이 완료되지 않았습니다. 정말 그만할까요?"}
              desc={
                "등록을 완료해주시면 부담없는 커피챗 제안을 받을 수 있어요."
              }
              btnText={"돌아가기"}
              onClose={onExit}
              closeBtnText={"나가기"}
            />
            <SimpleDialog
              onConfirm={onClickConfirmInvalidLink}
              open={invalidLinkOpenModal}
              title={"입력하신 링크가 유효하지 않습니다."}
              desc={"정보를 수정해주세요."}
              btnText={"확인"}
            />
            <SimpleDialog
              onConfirm={onSubmit}
              open={submitPhoneOpenModal}
              title={"휴대전화 번호가 유효하지 않습니다."}
              desc={"정보를 수정해주세요."}
              btnText={"확인"}
            />
            <SimpleDialog
              onConfirm={onSubmit}
              open={submitOpenModal}
              title={"입력하신 이메일이 유효하지 않습니다."}
              desc={"정보를 수정해주세요."}
              btnText={"확인"}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PersonalInfo;

const getKoreanAffix = (str: string, type: "은는" | "이가" | "과와") => {
  const lastChar = str.charCodeAt(str.length - 1);
  const hasLast = (lastChar - 0xac00) % 28;
  switch (type) {
    case "은는":
      return hasLast ? "은" : "는";
    case "이가":
      return hasLast ? "이" : "가";
    case "과와":
      return hasLast ? "과" : "와";
  }
};

const Input = styled.input`
  appearance: none;

  width: 14px;
  height: 14px;

  border: 1px solid rgba(42, 47, 61, 0.32);
  border-radius: 2px;

  &:checked {
    border-color: transparent;

    background-image: url(${Check});
    background-size: 100% 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: black;
  }
`;
