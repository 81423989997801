import { Box, List, ListItem, ListItemButton, Slide } from '@mui/material';
import { FC } from 'react';
import styles from "./BottomSheet.module.css";
import CheckIcon from '@mui/icons-material/Check';

type Props = {
    open: boolean;
    title: string;
    list: Array<any>;
    onClickOption: (option: any) => void
    selectedOption: any;
    onCloseBottomSheet: () => void
    maxHeight?: string
}

const BottomSheet: FC<Props> = ({open, title, list, onClickOption, selectedOption, onCloseBottomSheet, maxHeight = "fit-content"}) => {
    
    const bottomSheet: React.CSSProperties = {
        width: "inherit", maxWidth: "414px", minWidth:"320px", height: "100%", backgroundColor: '#FFFFFF', position: 'fixed', bottom: '0', borderRadius: '16px 16px 0 0', boxShadow: '0px 2px 32px rgba(0, 0, 0, 0.24)'
     }
     const deemedStyle: React.CSSProperties = {
        width: "100vh",  maxWidth: "414px", minWidth:"320px", height: "inherit", minHeight: '100vh',backgroundColor: 'rgba(28, 31, 41, 0.8)', position: 'fixed'
    }

    return (
        <>
        {open && 
            <div style={deemedStyle} onClick={onCloseBottomSheet}></div>
        }
        <Slide 
            direction="up"
            in = {open}
            mountOnEnter 
            unmountOnExit
            style={{maxHeight: maxHeight}}
        >
            <div style={bottomSheet}>
                <Box
                    role="slider"
                    style={{height: '100%', overflow: 'hidden'}}
                >
                    <List style={{height: '100%', overflow: 'hidden'}}>
                        <div className={styles.title}>{title}</div>
                        <div className={styles.scroll_cover}>
                            <div className={styles.scroll_content} onScroll = {(e) => {e.stopPropagation()}}>
                                {list.map((option) => 
                                    <ListItem key={option} disablePadding>
                                        <ListItemButton className={styles.option_list} onClick={() => onClickOption(option)}>
                                            <div className={styles.option_list_txt}>{option}</div>
                                            {option === selectedOption &&
                                            <CheckIcon style={{ color: '#5E67EB' }}/>
                                            }
                                        </ListItemButton>
                                    </ListItem>
                                )}
                            </div>
                        </div>
                    <div className={styles.btm_margin}></div>
                    </List>
                </Box>
            </div>
        </Slide>
        </>
        )
}

export default BottomSheet