import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./Complete.module.css";
import invite_gift from "../../../assets/invite_gift.png";

const Complete = () => {
  const navigate = useNavigate();
  const onConfirm = () => {
    navigate(`/worker/invite/my_recommendation`);
  };

  return (
    <div className={styles.background}>
      <div>
        <div className={styles.content}>
          <div className={styles.title}>초대장 발송을 완료했어요!</div>
          <div className={styles.message_wrap}>
            <img
              className={styles.gift_img}
              alt="invite_gift"
              src={invite_gift}
            />
            <div className={styles.message_area}>
              <div className={styles.message_title}>COFFEECHAT INVITE</div>
              <div className={styles.message_content}>
                초대 받는 분이 확인 후 2주 이내 정보 입력을 완료하면 나의
                추천에서 코멘트를 작성할 수 있습니다.
              </div>
            </div>
          </div>
          <button className={styles.send_invitation} onClick={onConfirm}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default Complete;
