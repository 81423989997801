import { FC } from "react";
import styles from "./Default.module.css";
import { useMediaQuery } from "react-responsive";
import img_default from "../assets/default.png";
import img_default_footer from "../assets/default_footer.png";

type Props = {};

const Default: FC<Props> = () => {
  return (
    <div className={styles.background}>
      <>
        <img src={img_default} alt="default" className={styles.image} />
      </>
      <>
        <img src={img_default_footer} alt="footer" className={styles.image} />
      </>
    </div>
  );
};

export default Default;
