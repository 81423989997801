import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./PresentConfirm.module.css";
import Error from "../../../common/Error";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";

const PresentConfirm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  if (location.state) {
    const { name, message, company } = location.state;

    const onClickBack = () => {
      navigate(`/worker/invite/present`, {
        state: { name: name, message: message },
      });
    };

    const onClickConfirm = () => {
      navigate(`/worker/invite/complete`);
    };

    return (
      <>
        <div className={styles.background}>
          <div>
            <div className={styles.content}>
              <div className={styles.back_arrow}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={onClickBack}
                >
                  <ArrowBackIcon />
                </IconButton>
              </div>
              <div className={styles.content_info}>
                <div className={styles.title}>초대장 발송</div>
                <div className={styles.transfer_info}>초대장 전송 정보</div>
                <div className={styles.receipent_wrap}>
                  <div className={styles.receipent}>받는 분</div>
                  <div className={styles.name}>{name}</div>
                </div>
                <div className={styles.company_info}>
                  <span className={styles.text_wrap}>
                    <span className={styles.company_name}>{company}</span>
                    <span className={styles.company_text}>
                      사내추천으로 초대 받았어요!
                    </span>
                  </span>
                </div>
                <div className={styles.message_box}>
                  <div className={styles.message_header}>
                    {name}님께 보내는 메시지
                  </div>
                  <div className={styles.message_body}>{message}</div>
                </div>
              </div>
              <div className={styles.bottom_button}>
                <div className={styles.gradation_background} />
                <div className={styles.solid_background}>
                  <button
                    className={styles.confirm_button}
                    onClick={onClickConfirm}
                  >
                    완료하기
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Error />
      </>
    );
  }
};

export default PresentConfirm;
